/* istanbul ignore file */
import {createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {baseStylesParams} from '../../styleParams/baseStylesParams';
import {IStylesParams, StylesParamKeys} from '../../styleParams/types';
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';
import {updatedStyleParamsOverrides} from '../../styleParams/updatedStyleParamsOverrides';

export const customCssVars: CustomCssVarsFn = (params: CustomCssVarsFnParams) => ({
  ...baseCustomCssVars(params),
});

const stylesParams: IStylesParams = {
  ...baseStylesParams,
  ...updatedStyleParamsOverrides,
  galleryShowFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryShowSort: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showCategoryHeaderImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoryHeaderName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoryHeaderDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoriesBreadcrumbs: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategories: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_layoutVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
